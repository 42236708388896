<template>
    <div
        class="mt-3 rounded p-3 bg-bg shadow"
    >
        <div
            class="expand flex justify-between text-right cursor-pointer select-none"
            data-test-id="toggle-translation-settings"
            @click="isSettingsOpen = !isSettingsOpen"
        >
            <h3
                class="text-sm mb-1 font-bold"
            >
                {{ $t('ANALYSIS.TRANSLATION_SETTINGS', 'Translation settings') }}
            </h3>
            <icon
                :icon="isSettingsOpen ? 'angle-up' : 'angle-down'"
                class="text-base ml-1 text-black relative"
            />
        </div>
        <info-box
            v-if="isExcelReanalysis && (isTranslationWithoutHtmlActive || isTranslationWithHtmlActive)"
            type="warning"
            class="mt-2 text-sm"
        >
            {{ $t('ANALYSIS.TRANSLATE_DATASET_EXCEL_QUOTA_WARNING', 'Please note that reanalysing File Upload datasets will result in further Translation Quota consumption even if there aren’t any changes regarding the analysed dimensions.') }}
        </info-box>
        <div
            v-if="isSettingsOpen"
            data-test-id="translation-settings"
        >
            <info-box
                type="warning"
                class="my-2 text-sm"
            >
                <div v-html="infoBoxText" />
            </info-box>
            <toggle-box
                class="my-2"
                :model-value="isInputTranslationActive"
                :info-text="inputTranslationInfoText"
                :disabled="datasetTranslationPlanFeatureQuota === 0 || languageId === 10 || isEdit"
                :label="$t('ANALYSIS.INPUT_TRANSLATION_LABEL', 'Translate <b>input data</b> automatically')"
                @update:modelValue="$emit('inputTranslationUpdated', $event)"
            />
            <toggle-box
                class="my-2"
                data-test-id="translate-dataset"
                :model-value="isTranslationWithoutHtmlActive"
                :info-text="translateDatasetInfoText"
                :disabled="datasetTranslationPlanFeatureQuota === 0 || languageId === 2"
                :label="$t('ANALYSIS.TRANSLATE_DATASET_LABEL', 'Translate <b>analyzed data</b> automatically')"
                @update:modelValue="$emit('translationWithoutHtmlUpdated', $event)"
            />
            <toggle-box
                class="my-2"
                :model-value="isTranslationWithHtmlActive"
                :info-text="translateDatasetWithHtmlInfoText"
                :disabled="datasetTranslationPlanFeatureQuota === 0 || languageId === 2"
                :label="$t('ANALYSIS.TRANSLATE_DATASET_WITH_HTML_LABEL', 'Translate <b>analyzed data</b> automatically <b>with HTML formatting</b>')"
                data-test-id="translate-dataset-with-html"
                @update:modelValue="$emit('translationWithHtmlUpdated', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, toRefs, defineComponent, watch, onMounted } from 'vue';
import Icon from '@/components/ui/Icon.vue';
import useSubscriptionStore from '@/subscription/store';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import InfoBox from '@/components/ui/InfoBox.vue';
import i18next from 'i18next';
import ToggleBox from '@/common/components/design-system/ToggleBox.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import { setLoading } from '@/utils/loading';

export default defineComponent({
    components: {
        ToggleBox,
        InfoBox,
        Icon
    },
    props: {
        languageId: { type: Number, default: 1 },
        isTranslationWithoutHtmlActive: { type: Boolean, default: false },
        isTranslationWithHtmlActive: { type: Boolean, default: false },
        isExcelReanalysis: { type: Boolean, default: false },
        isDatasetTranslationOff: { type: Boolean, default: false },
        isInputTranslationActive: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    setup(props, ctx) {
        const  { useState, useActions }  = createNamespacedHelpers('settings/teamSettings');
        const { sensitive_data_settings } = useState(['sensitive_data_settings']);
        const { loadTeam } = useActions(['loadTeam']);

        onMounted(async () => {
            setLoading(true);
            await loadTeam();
            setLoading(false);
        });

        const isSettingsOpen = ref(false);
        const datasetTranslationPlanFeatureQuota = computed(() => {
            const datasetTranslationPlanFeatureUsage = planFeatureUsages.value.find(usage => usage.feature === planFeatureTypeEnum.DATASET_TRANSLATION);
            return datasetTranslationPlanFeatureUsage ? datasetTranslationPlanFeatureUsage?.max_value - datasetTranslationPlanFeatureUsage?.value : 0;
        });

        const datasetTranslationPlanFeatureResetDate = computed(() => {
            const datasetTranslationPlanFeatureUsage = planFeatureUsages.value.find(usage => usage.feature === planFeatureTypeEnum.DATASET_TRANSLATION);
            return datasetTranslationPlanFeatureUsage ? datasetTranslationPlanFeatureUsage?.resets_at : null;
        });

        const { planFeatureUsages } = toRefs(useSubscriptionStore().state);

        const quotaInfoText = computed(() => {
            return i18next.t('ANALYSIS.TRANSLATE_DATASET_QUOTA_INFO_TEXT', {
                quota: datasetTranslationPlanFeatureQuota.value,
                defaultValue: 'Current quota status: You have <b>{{quota}} characters left</b> to translate'
            });
        });

        const infoBoxText = computed(() =>
            i18next.t('ANALYSIS.TRANSLATE_DATASET_WARNING_TEXT', 'Our translation features utilize Google\'s Cloud Translation service. Please ensure that the text you wish to analyze fits within your character quota. The system will automatically alert the group administrator when quota usage reaches certain thresholds (70%, 90%, 99%, 100%). If you require additional quota, please reach out to your account manager.') +
            '<br>' +
            quotaInfoText.value +
            (datasetTranslationPlanFeatureResetDate.value ? i18next.t('ANALYSIS.TRANSLATE_DATASET_UNTIL', 'until') + ': ' + datasetTranslationPlanFeatureResetDate.value : '')
        );

        const translateDatasetInfoText = computed(() =>
            i18next.t('ANALYSIS.TRANSLATE_DATASET_INFO_TEXT','Enabling this feature will automatically translate analyzed data to English, while text analysis occurs in the original language. In static outputs (e.g., Semantic Excel Export), English translations will be provided. Translations will also be activated automatically on both the dashboard and feed.') +
            '<br>' +
            i18next.t('ANALYSIS.TRANSLATE_DATASET_DISABLED_INFO_TEXT', 'This function is disabled if the Analysis Language is set to English.')
        );

        const translateDatasetWithHtmlInfoText = computed(() =>
            i18next.t('ANALYSIS.TRANSLATE_DATASET_WITH_HTML_INFO_TEXT','Enabling this feature will automatically translate analyzed data to English while incorporating HTML formatting for verbatim visualization on the feed and in drill downs. All topics, custom labels, and phrases with sentiment values will be highlighted, enhancing the transparency and understanding of text analysis results even without knowledge of the original language.') +
            '<br><br>' +
            i18next.t('ANALYSIS.TRANSLATE_DATASET_IMPORTANT_INFO_TEXT', 'Important Considerations') + ':' +
            '<ul class="list-disc pl-4"><li>' + i18next.t('ANALYSIS.TRANSLATE_DATASET_IMPORTANT_FIRST_LINE_INFO_TEXT', 'Usage of this translation method may significantly increase quota consumption compared to standard translation without HTML formatting.') + '</li>' +
            '<li>' + i18next.t('ANALYSIS.TRANSLATE_DATASET_IMPORTANT_SECOND_LINE_INFO_TEXT', 'Translation with HTML formatting may result in up to three times the quota usage compared to the total character count of text units in the dataset.') + '</li></ul>'
        );

        const inputTranslationInfoText = computed(() =>
            i18next.t('ANALYSIS.INPUT_TRANSLATION_INFO_TEXT','When utilizing this feature, input data will be automatically translated to the language specified in the Analysis Settings above as Analysis Langauge. Please note that translation may increase data processing time.') +
            '<br><br>' +
            i18next.t('ANALYSIS.TRANSLATE_DATASET_IMPORTANT_INFO_TEXT', 'Important Considerations') + ':' +
            '<ul class="list-disc pl-4"><li>' + i18next.t('ANALYSIS.INPUT_TRANSLATION_IMPORTANT_FIRST_LINE_INFO_TEXT', 'If the analysis language is set to Mixed language, the input data translation feature will be disabled.') + '</li><li>' +
            i18next.t('ANALYSIS.INPUT_TRANSLATION_IMPORTANT_SECOND_LINE_INFO_TEXT', 'Ensure that you set up the correct Analysis Language, as changes cannot be made later.') + '</li><li>' +
            i18next.t('ANALYSIS.INPUT_TRANSLATION_IMPORTANT_THIRD_LINE_INFO_TEXT', 'Once input translation is activated, it cannot be deactivated. Additionally, datasets created without this function activated cannot be modified later.') + '</li>' +
            (sensitive_data_settings.value && Object.values(sensitive_data_settings.value).some(Boolean) ? '<li>' + i18next.t('ANALYSIS.INPUT_TRANSLATION_IMPORTANT_FOURTH_LINE_INFO_TEXT', 'Sensitive Data Masking function is not available if Input Data Translation is activated.') + '</li>' : '') +
            '</ul>' +
            '<br>' +
            i18next.t('ANALYSIS.TRANSLATE_DATASET_QUOTA_MANAGEMENT_INFO_TEXT', 'Translation Quota Management') + ':' +
            '<ul class="list-disc pl-4"><li>' + i18next.t('ANALYSIS.TRANSLATE_DATASET_QUOTA_MANAGEMENT_FIRST_LINE_INFO_TEXT', 'Data processing will halt if translation quota is exhausted. Processing will resume once sufficient quota becomes available.') + '</li>' +
            '<li>' + i18next.t('ANALYSIS.TRANSLATE_DATASET_QUOTA_MANAGEMENT_SECOND_LINE_INFO_TEXT', 'Following the restoration of archived datasets, the input translation feature will automatically resume translation processes if sufficient quota is available.') + '</li></ul>'
        );

        const languageId = computed(() => props.languageId);

        watch(languageId, () => {
            // English case
            if (languageId.value === 2 && props.isTranslationWithoutHtmlActive) {
                ctx.emit('translationWithoutHtmlUpdated', false);
            }
            if (languageId.value === 2 && props.isTranslationWithHtmlActive) {
                ctx.emit('translationWithHtmlUpdated', false);
            }
            // Mixed lang case
            if (languageId.value === 10 && props.isInputTranslationActive) {
                ctx.emit('inputTranslationUpdated', false);
            }
        });

        return {
            isSettingsOpen,
            quotaInfoText,
            datasetTranslationPlanFeatureQuota,
            infoBoxText,
            translateDatasetInfoText,
            translateDatasetWithHtmlInfoText,
            inputTranslationInfoText
        };
    }
});
</script>
