<template>
    <div>
        <div class="mb-3">
            <div class="flex w-full">
                <custom-label-languages
                    class="flex-grow"
                    :model-value="phraseSet.languageId"
                    :filterable-languages="languages.filter(l => l !== phraseSet.languageId)"
                    @update:modelValue="changePhraseSetLanguage($event)"
                />
                <div
                    v-if="!(newPhraseAdded && isNew)"
                    class="text-right mt-6 ml-3 py-1 cursor-pointer"
                >
                    <z-icon
                        class="text-red-700"
                        icon="trash"
                        data-test-id="trash"
                        @click="$emit('deletePhraseSet')"
                    />
                </div>
            </div>
            <span
                v-if="phraseSet.languageId === null && newPhraseAdded && isNew"
                class="text-red-700 text-sm"
            > {{ $t('CUSTOM_LABEL_POPUP_MISSING_LANG', 'Language is missing') }}</span>
        </div>
        <div class="mb-3">
            <label class="text-default text-black block w-full pl-2 mb-2">
                {{ $t('KEYWORD.SYNONYMS') }}
                <z-icon
                    v-tooltip.top="$t('KEYWORD.SYNONYMS_HELP')"
                    icon="question-circle"
                />
            </label>
            <tags-input-field
                :tag-list="phraseSet.synonyms"
                tag-type="synonyms"
                :placeholder="$t('KEYWORD.SYNONYMS_PLACEHOLDER')"
                data-test-id="synonymsTagInput"
                @input="suggest"
                @removeTag="removeFromPhraseSet({ type: 'synonyms', value: $event })"
            />
            <suggestions
                v-if="shouldSuggestionsBeVisible && phraseSet.languageId"
                :suggestions="suggestions"
                :is-loading="isSuggestionsLoading"
                @add="addToPhraseSet({ type: 'synonyms', value: $event })"
            />
        </div>
        <div class="mb-3">
            <label class="text-default text-black block w-full pl-2 mb-2">
                {{ $t('KEYWORD.EXCLUDES') }}
                <z-icon
                    v-tooltip.top="$t('KEYWORD.EXCLUDES_HELP')"
                    icon="question-circle"
                />
            </label>
            <tags-input-field
                :tag-list="phraseSet.excludes"
                tag-type="excludes"
                :placeholder="$t('KEYWORD.EXCLUDES_PLACEHOLDER')"
                data-test-id="excludesTagInput"
                @input="addToPhraseSet({ type: 'excludes', value: $event })"
                @removeTag="removeFromPhraseSet({ type: 'excludes', value: $event })"
            />
        </div>
    </div>
</template>

<script lang="ts">
import TagsInputField from '@/components/ui/TagsInputField.vue';
import Suggestions from '@/custom-labels/components/CustomLabelPopup/CustomLabelPopupSuggestions.vue';
import CustomLabelLanguages from '@/custom-labels/components/CustomLabelPopup/CustomLabelLanguages/CustomLabelLanguages.vue';
import {defineComponent, ref} from 'vue';
import { getSynonymSuggestions } from '@/custom-labels/api/custom-labels';
import ZIcon from '@/components/ui/Icon.vue';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';

export default defineComponent({
    components: {
        TagsInputField,
        Suggestions,
        CustomLabelLanguages,
        ZIcon
    },
    props: {
        phraseSet: { type: Object, required: true },
        isNew: { type: Boolean, default: false },
        languages: { type: Array, required: true},
        newPhraseAdded: { type: Boolean, default: false }
    },
    setup(props, ctx) {
        const suggestions = ref([]);
        const isSuggestionsLoading = ref(false);
        const shouldSuggestionsBeVisible = ref(false);
        const changePhraseSetLanguage = (value) => {
            let newPhraseSet = {...props.phraseSet};
            newPhraseSet = {
                ...newPhraseSet,
                languageId: value
            };
            ctx.emit('phraseSetUpdated', newPhraseSet);
        };
        const addToPhraseSet = ({type, value}) => {
            if(value.length > 0) {
                if(typeof value === 'string') {
                    value = [value];
                }
                let newPhraseSet = {...props.phraseSet};
                let arrayToPush = [...newPhraseSet[type]];
                value.forEach(element => {
                    if(!arrayToPush.some(s => s === element)) {
                        arrayToPush.push(element);
                    }
                });
                newPhraseSet = {
                    ...newPhraseSet,
                    [type]: arrayToPush
                };
                ctx.emit('phraseSetUpdated', newPhraseSet);
            }
        };
        const removeFromPhraseSet = ({type, value}) => {
            let newPhraseSet = {...props.phraseSet};
            let arrayFromRemove = [...newPhraseSet[type]];
            arrayFromRemove = arrayFromRemove.filter(e => e !== value);
            newPhraseSet = {
                ...newPhraseSet,
                [type]: arrayFromRemove
            };
            ctx.emit('phraseSetUpdated', newPhraseSet);
        };

        const suggest = async (value) => {
            isSuggestionsLoading.value = true;
            shouldSuggestionsBeVisible.value = true;
            addToPhraseSet({ type: 'synonyms', value: value });
            if(!props.isNew && value.length > 0 && value[0] && props.phraseSet.languageId) {
                try {
                    const result = await getSynonymSuggestions({ words: value, type: 'synonym', languageId: props.phraseSet.languageId });
                    suggestions.value = result.filter((suggestion) => {
                        return props.phraseSet.synonyms.concat(props.phraseSet.excludes).indexOf(suggestion) === -1;
                    });
                } catch (e) {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                    Sentry.captureException(e);
                }
            }
            isSuggestionsLoading.value = false;
        };

        return {
            changePhraseSetLanguage,
            addToPhraseSet,
            removeFromPhraseSet,
            suggestions,
            suggest,
            isSuggestionsLoading,
            shouldSuggestionsBeVisible
        };
    }
});
</script>

