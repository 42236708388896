<template>
    <div
        class="category-settings bg-bg text-black flex-grow"
    >
        <z-input
            ref="newCategory"
            v-model="newCategory"
            type="text"
            :placeholder="$t('CUSTOM_LABELS.NEW_CATEGORY', 'Create a new Category')"
            @focus="showAddCategoryText = true"
            @update:modelValue="searchInCategories()"
            @keyup.enter="!categoryToEdit ? createNewCategory() : editCategory()"
        />
        <div
            v-if="showAddCategoryText"
            class="info-text text-neutral-800"
        >
            {{ !categoryToEdit ? $t('CUSTOM_LABELS.ADD_NEW_CATEGORY', 'Press Enter to add a new category') : editCategoryText() }}
        </div>
        <div
            class="categories-box"
        >
            <div
                v-if="selectedId !== null"
                class="category-line no-category text-black"
                @click="removeCategory()"
            >
                {{ $t('CUSTOM_LABELS.NO_CATEGORY', 'No category') }}
            </div>
            <div
                v-for="(category, index) in filteredCategories"
                :key="index"
                class="category-line hover:text-primary-700"
            >
                <div
                    class="category-name text-black"
                    :class="{ bold: isSelected(category) }"
                    :data-test-id="'category-name-' + index"
                    @click="categorySelected(category)"
                >
                    {{ category.name }}
                </div>
                <div
                    class="category-action-buttons"
                    :data-test-id="'category-action-buttons-' + index"
                >
                    <z-icon
                        class="icon trash text-neutral-700"
                        icon="trash"
                        @click="$emit('deleteCategory', (category.id))"
                    />
                    <z-icon
                        class="icon pen text-neutral-700"
                        icon="pen"
                        @click="setCategoryToEdit(category)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ZIcon from '@/components/ui/Icon';

export default {
    components: {
        ZIcon,
    },
    props: {
        categories: { type: Array, default: () => ([]) },
        selectedId: { type: Number, required: false, default: null },
    },
    data() {
        return {
            newCategory: null,
            showAddCategoryText: false,
            categoryToEdit: null,
            filteredCategories: null,
        };
    },
    watch: {
        categories() {
            this.filteredCategories = this.categories;
        }
    },
    mounted() {
        this.filteredCategories = this.categories;
    },
    methods: {
        categorySelected(category) {
            this.$emit('categorySelected', category);
        },
        removeCategory() {
            this.$emit('categorySelected', null);
        },
        isSelected(category) {
            return this.selectedId !== null && (category.id === this.selectedId);
        },
        async createNewCategory() {
            await this.$emit('newCategoryAdded', this.newCategory);
            this.newCategory = null;
            this.showAddCategoryText = false;
            this.filteredCategories = this.categories;
            this.$refs.newCategory.$refs.input.blur();
        },
        setCategoryToEdit(category) {
            this.showAddCategoryText = true;
            this.newCategory = category.name;
            this.categoryToEdit = category;
        },
        editCategoryText() {
            return this.$t('CUSTOM_LABELS.CATEGORY_EDIT_TEXT', {
                category: this.categoryToEdit.name,
                interpolation: { escapeValue: false },
                defaultValue: 'Press Enter to edit "{{category}}"'
            });
        },
        async editCategory() {
            await this.$emit('editCategory', { id: this.categoryToEdit.id, name: this.newCategory });
            this.newCategory = null;
            this.categoryToEdit = null;
            this.showAddCategoryText = false;
            this.filteredCategories = this.categories;
            this.$refs.newCategory.$refs.input.blur();
        },
        searchInCategories() {
            this.filteredCategories = this.categories.filter(category => category.name.toLowerCase().includes(this.newCategory.toLowerCase()));
        }
    }
};
</script>
<style lang="less">
    .category-settings {
        width: 100%;
        box-shadow: 0 6px 27px -8px #8392A5;
        position: absolute;
        z-index: 1000;
        padding: 5px;
    }
    .category-input {
        padding: 2px 5px;
        height: 30px;
        border-radius: 4px;
        width: 95%;
        display: block;
        border-bottom: unset !important;
        margin: 0 auto;
        font-size: 12px;
    }
    .category-line {
        display: flex;
        padding: 5px;
        font-size: 14px;

        .category-action-buttons{
            display:none;
        }
        .category-name, &.no-category {
            width: 100%;
            margin: 2px;
            cursor: pointer;
        }

        .bold {
            font-weight: bold;
        }

        .icon {
            font-size: 14px;
            float: right;
            margin: 5px 2px;
            cursor: pointer;
            display: none;
        }

        &:hover {
            .category-name {
                width: 70%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .category-action-buttons{
                width: 30%;
                display: unset;
            }
            .icon {
                display: unset;
            }
        }
    }
    .info-text {
        font-size: 12px;
        text-align: center;
        margin: 5px 10px;
    }

</style>
