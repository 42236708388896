<template>
    <tr>
        <th
            v-for="(columnName, index) in columnNames"
            :key="index"
            class="align-middle border-b p-2 truncate text-left"
            :class="{
                'bg-primary-300': isPrimaryColumn(index),
                'border-primary-700': isPrimaryColumn(index) === 2,
                'border-neutral-600': isPrimaryColumn(index) !== 2,
                'hidden': emptyColumns.includes(index),
            }"
        >
            <label
                class="block mb-1"
                :class="{
                    'text-small font-bold opacity-0': index !== 0 && columnType !== 'parameter',
                    'text-sm font-normal': columnType === 'parameter'
                }"
            >
                {{ columnType === 'parameter' ? $t('SURVEYFORMS.CONDITION_URL_PARAM', 'URL Parameter') : $t('ANALYSIS.COLUMN_NAMES') }}
            </label>
            <z-input
                v-if="!columnsWithSelectableName || !columnsWithSelectableName.includes(index)"
                type="text"
                :model-value="columnName"
                :data-test-id="'column-name-input-' + index"
                @blur="changeColumnName({index: index, newName: $event.target.value})"
            />
            <z-select
                v-else-if="filteredNameOptions.length > 0"
                :model-value="columnName"
                @update:modelValue="changeColumnName({index: index, newName: $event})"
            >
                <option
                    v-for="(option, optionIndex) in filteredNameOptions"
                    :key="optionIndex"
                    :value="option"
                >
                    {{ option }}
                </option>
            </z-select>
        </th>
    </tr>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import ZSelect from '@/components/ui/Select.vue';

export default defineComponent({
    components: { ZSelect },
    props: {
        columnNames: { type: Array, default: () => ([]) },
        primaryColumnIndexes: { type: Array, default: () => [] },
        columnNameOptions: { type: Array, default: () => ([]) },
        columnsWithSelectableName: { type: Array, default: () => ([]) },
        emptyColumns: { type: Array as () => Array<number>, default: () => [] },
        columnType: {
            type: String,
            default: 'column',
            validator: (value: string) => ['column', 'parameter'].includes(value),
        },
    },
    setup(props, context) {
        const filteredNameOptions = computed(() => props.columnNameOptions.filter(option => option !== ''));

        return {
            isPrimaryColumn: (i) => props.primaryColumnIndexes.indexOf(i) > -1,
            changeColumnName: ({ index, newName }) => context.emit(
                'columnNamesChanged',
                props.columnNames.map((name, i) => index === i ? newName : name)
            ),
            filteredNameOptions,
        };
    },
});
</script>
