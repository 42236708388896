import type { Campaign } from '@/campaign/types';

import { api } from '@/api/index.js';

export const fetchAllCampaigns = async (): Promise<Campaign[]> => {
    const response = await api.get('/campaigns');
    return response.data;
};

export const fetchCampaignsByDatasetId = async (datasetId: string, type: string|null = null): Promise<Campaign[]> => {
    const query = type ? `?type=${ type }` : '';
    const response = await api.get(`/campaigns/by-dataset-id/${ datasetId }${ query }`);
    return response.data;
};

export const fetchCampaignsByAudienceId = async (audienceId: number): Promise<Campaign[]> => {
    const response = await api.get(`/campaigns/by-audience-id/${ audienceId }`);
    return response.data;
};

export const deleteCampaignById = async (campaignId: string) => {
    await api.delete('/campaigns/' + campaignId);
};

export const createCampaign = async (campaign: Campaign) => {
    const response = await api.post('/campaigns', campaign);
    return response.data;
};

export const updateCampaign = async (campaign: Campaign) => {
    const response = await api.put('/campaigns/' + campaign.id, campaign);
    return response.data;
};

export const sendCampaign = async (campaignId: string): Promise<Campaign> => {
    const response = await api.post(`/campaigns/send/${campaignId}`);
    return response.data;
};

export const sendCampaignTest = async (campaignId: string, emails: string[]): Promise<Campaign> => {
    const response = await api.post(`/campaigns/send/${campaignId}/test`, { emails });
    return response.data;
};

export const sendCampaignReminder = async (campaignId: string, waveId: string, type: string): Promise<Campaign> => {
    const response = await api.post(`/campaigns/send/${campaignId}/reminder`, { waveId, type });
    return response.data;
};
